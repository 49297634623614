import { createApp } from "vue";
import App from "./App.vue";
import PrimeVue from "primevue/config";
import "primevue/resources/themes/lara-light-teal/theme.css";
import Vueform from "@vueform/vueform";
import vueformConfig from "./../vueform.config";
import { router } from "./router";

const app = createApp(App);
app.use(PrimeVue);
app.use(Vueform, vueformConfig);
app.use(router);
app.mount("#app");
