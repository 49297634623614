<template>
    <div class="card">
        <TabView>
            <TabPanel header="Pending">
                <pending-payment></pending-payment>
            </TabPanel>
          <TabPanel header="Waiting">
            <waiting-payment></waiting-payment>
          </TabPanel>
            <TabPanel header="Completed">
                <completed-payment></completed-payment>
            </TabPanel>
            <TabPanel header="Rejected">
               <reject-payment></reject-payment>
            </TabPanel>
        </TabView>
    </div>
</template>

<script>
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import PendingPayment from './PendingPayment.vue';
import CompletedPayment from './CompletedPayment.vue';
import WaitingPayment from "@/components/WaitingPayment";
import RejectPayment from "@/components/RejectPayment";

export default {
  components:{
    RejectPayment,
    WaitingPayment,
    TabView,
    TabPanel,
    PendingPayment,
    CompletedPayment
  },
};
</script>
