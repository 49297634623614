<template>
  <div>
    <h1 class="title">Create Payment Order</h1>
    <Vueform v-model="paymentRequestData">
      <GroupElement name="account" label="Account information">
        <select-element
          :native="true"
          name="areaCode"
          :items="areaCode"
          :columns="5"
        ></select-element>
        <TextElement
          name="phoneNumber"
          placeholder="Phone Number"
          :columns="7"
          rules="required|digits_between:8,12|integer"
        />
        <TextElement name="nickName" placeholder="Nick Name" rules="required" />
        <TextElement name="amount" placeholder="Amount" rules="required" />
      </GroupElement>
      <ButtonElement
        name="submit"
        add-class="mt-2"
        @click="CreatePaymentRequest"
      >
        Create
      </ButtonElement>
    </Vueform>
    <payment-management></payment-management>
  </div>
</template>
<script>
import axios from "axios";
import PaymentManagement from "./PaymentManagement.vue";

export default {
  components: {
    PaymentManagement,
  },
  created() {
    this.url = process.env.VUE_APP_APIURL;
  },
  data: () => ({
    paymentRequestData: {
      phoneNumber: "",
      nickName: "",
      amount: 0,
    },
    areaCode: [
      { value: "+66", label: "Thailand" },
      { value: "+91", label: "India" },
      { value: "+84", label: "Vietnam" },
      { value: "+63", label: "Philippines" },
      { value: "+62", label: "Indonesia" },
    ],
    url: "",
  }),
  methods: {
    async CreatePaymentRequest() {
      try {
        const response = await axios.post(
          this.url + "/api/Admin/CreatePaymentRequest",
          this.paymentRequestData
        );

        // Check the response status and handle it as needed
        if (response.status === 200) {
          alert("CreatePayment Success!");
          window.location.reload();
        } else {
          console.error("CreatePayment Failed.");
          // Handle errors or display error messages to the user.
        }
      } catch (error) {
        console.error("An error occurred while submit:", error);
        // Handle network errors or other unexpected issues.
      }
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.form {
  max-width: 300px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 15px;
}

.label {
  display: block;
  margin-bottom: 5px;
}

.input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.button:hover {
  background-color: #0056b3;
}
</style>
