
import en from '@vueform/vueform/locales/en'
import vueform from '@vueform/vueform/themes/vueform'

// You might place these anywhere else in your project
import '@vueform/vueform/themes/vueform/css/index.min.css';

export default {
    theme: vueform,
    locales: { en },
    locale: 'en',
}
