<template>
  <h1 class="title">Login</h1>
  <Vueform v-model="loginData" ref="form$">
    <GroupElement name="login_information">
      <TextElement name="account" placeholder="Account" rules="required" />
      <TextElement
        name="password"
        input-type="password"
        placeholder="Password"
        rules="required"
      />
    </GroupElement>

    <ButtonElement name="submit" add-class="mt-2" @click="handleLogin">
      Login
    </ButtonElement>
  </Vueform>
</template>

<script>
import Cookies from "js-cookie";
import axios from "axios";

export default {
  created() {
    this.url = process.env.VUE_APP_APIURL;
  },
  data: () => ({
    loginData: {
      account: '',
      password: '',
      token: ''
    },
    url: '',
  }),

  methods: {
    async handleLogin() {
      //const token = "asds32adsavrAS3Fadf5567"; // Call Login Here
      let username = this.loginData.username;
      let password = this.loginData.password;

      console.log('Account: ', this.loginData.account);
      console.log('Password: ', this.loginData.password);

      if (username !== "" && password !== "") {
        const response = await axios.post(
          this.url + "/api/Account/AdminLogin", this.loginData
        );

        this.loginData.token = response.data;
      } else {
        alert("Account & Password cannot be empty!");
      }

      Cookies.set("login", JSON.stringify(this.loginData), { expires: 1 });

      if (Cookies.get('login') && this.loginData.token) {
        this.$router.push({ path: "/main" });
      }
    },

    async removeCookie() {
      Cookies.remove("login");
    },
  },
};
</script>
