import { createRouter, createWebHashHistory } from "vue-router";
import PaymentCreated from "./components/PaymentCreated.vue";
import PendingPayment from "./components/PendingPayment.vue";

import LoginPage from "./components/LoginPage.vue";
import Cookies from "js-cookie";

export const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    { path: "/", name: "Login", component: LoginPage },
    {
      path: "/main",
      name: "Main",
      component: PaymentCreated,
      children: [{ path: "", component: PendingPayment }],
      meta: { requireAuth: true },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  console.log("to: ", to);
  console.log("from: ", from);

  if (to.meta.requireAuth) {
    const info = Cookies.get("login");

    if (info) {
      console.log("Login info ", info);
      const token = JSON.parse(info).token;
      console.log("Login Token ", token);

      if (token.length > 0 || token === undefined) {
        next();
      } else {
        next({ name: "Login" });
      }
    } else {
      next({ name: "Login" });
    }
  } else {
    next();
  }
});
