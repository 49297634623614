<template>
  <div class="card">
    <h1 class="title">Pending Claim Orders</h1>
    <DataTable
      :value="pendingProducts"
      sortMode="multiple"
      tableStyle="min-width: 50rem"
    >
      <Column
        field="nickName"
        header="NickName"
        sortable
        style="width: 10%"
      ></Column>
      <Column field="phone" header="Phone" style="width: 15%"></Column>
      <Column field="amount" header="Amount" sortable style="width: 5%">
        <template #body="slotProps">
          {{ formatCurrency(slotProps.data.amount, slotProps.data.country) }}
        </template>
      </Column>
      <Column field="retry" header="retry" style="width: 5%"></Column>
      <Column field="country" header="country" style="width: 10%"></Column>
      <Column
        field="createdOn"
        header="Time (GMT+8)"
        sortable
        style="width: 15%"
      >
        <template #body="slotProps">
          {{ getLocalDateString(slotProps.data.createdOn) }}
        </template>
      </Column>
      <Column :exportable="false" style="min-width: 8rem; width: 10%">
        <template #body="slotProps">
          <Button
            label="reject"
            outlined
            rounded
            severity="danger"
            @click="rejectPaymentRequest(slotProps.data.id)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
  <div class="card">
    <h1 class="title">Pending Approve Orders</h1>
    <DataTable
      :value="waitingApproveProducts"
      sortMode="multiple"
      tableStyle="min-width: 50rem"
    >
      <Column
        field="nickName"
        header="NickName"
        sortable
        style="width: 10%"
      ></Column>
      <Column field="phone" header="Phone" style="width: 15%"></Column>
      <Column field="country" header="country" style="width: 10%"></Column>
      <Column field="amount" header="Amount" sortable style="width: 5%">
        <template #body="slotProps">
          {{ formatCurrency(slotProps.data.amount, slotProps.data.country) }}
        </template>
      </Column>
      <Column
        field="transactionId"
        header="Transaction"
        style="width: 15%"
      ></Column>
      <Column field="bankId" header="Bank" style="width: 5%"></Column>
      <Column
        field="bankOwner"
        header="Bank Owner"
        sortable
        style="width: 15%"
      ></Column>
      <Column
        field="bankNumber"
        header="Bank Number"
        sortable
        style="width: 10%"
      ></Column>
      <Column
        field="createdOn"
        header="Time (GMT+8)"
        sortable
        style="width: 15%"
      >
        <template #body="slotProps">
          {{ getLocalDateString(slotProps.data.createdOn) }}
        </template>
      </Column>
      <Column :exportable="false" style="min-width: 8rem; width: 10%">
        <template #body="slotProps">
          <Button
            label="approve"
            outlined
            rounded
            @click="
              approve(slotProps.data.transactionId, slotProps.data.countryId)
            "
          />
          <Button
            label="reject"
            outlined
            rounded
            severity="danger"
            @click="
              reject(slotProps.data.transactionId, slotProps.data.countryId)
            "
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import axios from "axios";
import Button from "primevue/button";

export default {
  components: {
    DataTable,
    Column,
    Button,
  },
  created() {
    this.url = process.env.VUE_APP_APIURL;
  },
  data() {
    return {
      pendingProducts: null,
      waitingApproveProducts: null,
      url: "",
    };
  },
  async mounted() {
    this.pendingProducts = await this.GetAllPendingClaimRequest();
    this.waitingApproveProducts = await this.GetTransaction(1);
  },
  methods: {
    async GetTransaction(status) {
      try {
        const response = await axios.get(
          this.url + "/api/Admin/GetTransaction?status=" + status
        );

        // Check the response status and handle it as needed
        if (response.status === 200) {
          return response.data;
        } else {
          console.error("GetPendingTransaction failed.");
          // Handle errors or display error messages to the user.
        }
      } catch (error) {
        console.error("An error occurred while submit:", error);
        // Handle network errors or other unexpected issues.
      }
    },
    async GetAllPendingClaimRequest() {
      try {
        const response = await axios.get(
          this.url + "/api/Admin/GetAllPendingClaimRequest"
        );

        // Check the response status and handle it as needed
        if (response.status === 200) {
          return response.data;
        } else {
          console.error("GetPendingTransaction failed.");
          // Handle errors or display error messages to the user.
        }
      } catch (error) {
        console.error("An error occurred while submit:", error);
        // Handle network errors or other unexpected issues.
      }
    },
    formatCurrency(value, country) {
      if (country == "vn") {
        return value.toLocaleString("vn-VN", {
          style: "currency",
          currency: "VND",
        });
      }

      if (country == "ph") {
        return value.toLocaleString("en-PH", {
          style: "currency",
          currency: "PHP",
        });
      }

      return value.toLocaleString("th-TH", {
        style: "currency",
        currency: "THB",
      });
    },
    async approve(transId, countryId) {
      const response = await axios.get(
        `${this.url}/api/Admin/ApprovePaymentOrder?transId=${transId}&countryId=${countryId}`
      );
      console.log("Approve: " + response.data);
      alert("Approve Success");
      window.location.reload();
    },
    async reject(transId, countryId) {
      console.log("Reject: " + transId);
      await axios.get(
        `${this.url}/api/Admin/RejectPaymentOrder?transId=${transId}&countryId=${countryId}`
      );
      alert("Reject Success");
      window.location.reload();
    },
    async rejectPaymentRequest(id) {
      console.log("Reject: " + id);
      await axios.get(`${this.url}/api/Admin/RejectPaymentRequest?id=${id}`);
      alert("Reject Success");
      window.location.reload();
    },
    getLocalDateString(utcStr) {
      return new Date(utcStr + "Z").toLocaleString(undefined, {
        timeZone: "Asia/Hong_Kong",
      });
    },
  },
};
</script>
